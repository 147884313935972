import React, { useRef } from 'react';
import JoditEditor from 'jodit-react';

// https://xdsoft.net/jodit/docs/

export const HtmlEditor = (props) => {
  const { htmlContent, onEditHtmlContent } = props;
  const editor = useRef(null);

  const config = {
    height: '65vh',
    allowResizeY: false,
    statusbar: false,
    addNewLine: false,
    buttons: ['undo', 'redo', 'bold', 'italic', 'underline', 'ul', 'ol', 'link', 'source', 'preview', {
      name: 'Online HTML Formatter',
      exec: () => {
        window.open('https://codebeautify.org/htmlviewer');
      }
    }]
  };

  return (
    <JoditEditor
      ref={editor}
      value={htmlContent}
      config={config}
      onBlur={newContent => onEditHtmlContent(newContent)} // preferred to use only this option to update the content for performance reasons
      onChange={() => { }}
    />
  );
};