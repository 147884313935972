import React, { Component } from 'react';
import { withTranslation } from "react-i18next";
import { Button, Select, } from "antd";
import PropTypes from 'prop-types';

import BaseFooter from "../../../../../../../../../components/BaseFooter/BaseFooter";
import LoadingWrapper from "../../../../../../../../../components/LoadingWrapper/LoadingWrapper";
import EditTop10Modal from "../EditTop10Modal/EditTop10Modal";
import PropertiesBody from "./PropertiesBody/PropertiesBody";
import VideoModal from "../VideoModal/VideoModal";
import ConfigModal from "../../../../../../../../../components/ConfigModal/ConfigModal";
import ConfigureCategoriesModal from "../ConfigureCategoriesModal/ConfigureCategoriesModal";
import EditCatalogModal from "../PropertiesViewDetails/EditCatalogModal/EditCatalogModal";
import CopyCatalogModal from '../PropertiesViewDetails/CopyCatalogModal/CopyCatalogModal';
import EditPropertyModal from "../EditPropertyModal/EditPropertyModal";
import MovieSpotlightModal from './MovieSpotlightModal/MovieSpotlightModal';
import SceneSpotlightModal from './SceneSpotlightModal/SceneSpotlightModal';

import { ModalController } from "../../../../../../../../../controllers/modal-controller/modal.controller";

import { getScrollHeight } from "../../../../../../../../../services/util-service/util.service";

import './PropertiesMainView.scss';
import SetBrowseConfigurationModal from "../SetBrowseConfigurationModal/SetBrowseConfigurationModal";
import EditSpecialCategoriesModal from '../PropertiesViewDetails/EditSpecialCategoriesModal/EditSpecialCategoriesModal';
import EditNATSCodeModal from '../PropertiesViewDetails/EditNATSCodeModal/EditNATSCodeModal';
import HomePageSpotlightModal from '../HomePageSpotlightModal/HomePageSpotlightModal';
import EditBannedWordsModal from '../PropertiesViewDetails/EditBannedWordsModal/EditBannedWordsModal';
import EditSearchOptionsModal from '../EditSearchOptionsModal/EditSearchOptionsModal';
import EditHTMLContentModal from '../PropertiesViewDetails/EditHTMLContentModal/EditHTMLContentModal';

class PropertiesMainView extends Component {

  state = {
    filters: {
      display: 'Visible'
    },
    displayOptions: ['All', 'Visible', 'Hidden']
  }

  tableRowHeight = 54;

  getScrollHeight = () => {
    const { contentHeight, listInfo } = this.props;
    const reductionHeight = 170;
    return getScrollHeight(contentHeight, listInfo.properties, this.tableRowHeight, reductionHeight);
  };

  renderFooter = () => {
    const { t } = this.props;
    return (
      <BaseFooter>
        <Button onClick={this.showPlaylistPlayerModal}>
          {t('PropertiesMainView.showPlayList')}
        </Button>
        <Button onClick={this.showPlayerModal}>
          {t('PropertiesMainView.showPlayer')}
        </Button>
        <Button onClick={this.showPlayerConfigModal}>
          {t('PropertiesMainView.showPlayerConfig')}
        </Button>
      </BaseFooter>
    );
  };

  showEditCatalogModal = ({ id: propertyId, name: propertyName }) => {
    const { onChange } = this.props;
    const modal = (
      <EditCatalogModal
        onChange={onChange}
        propertyId={propertyId}
        propertyName={propertyName}
      />
    );
    ModalController.showModal(modal);
  };

  showCopyCatalogModal = ({ id, name }) => {
    const modal = <CopyCatalogModal propertyId={id} propertyName={name} />;
    ModalController.showModal(modal);
  };

  showEditPropertyModal = ({ id: propertyId, name: propertyName }) => {
    const modal = (
      <EditPropertyModal propertyId={propertyId} propertyName={propertyName} />
    );
    ModalController.showModal(modal);
  };

  showConfigureCategoriesModal = (item) => {
    const { id: propertyId, name } = item;
    const modal = (
      <ConfigureCategoriesModal propertyId={propertyId} propertyName={name} />
    );
    ModalController.showModal(modal);
  };

  showSetBrowseConfigurationModal = (item) => {
    const { id: propertyId, name } = item;
    const modal = (
      <SetBrowseConfigurationModal propertyId={propertyId}
        propertyName={name} />
    );
    ModalController.showModal(modal);
  };

  showEditTop10Modal = (item) => {
    const { id: propertyId, name } = item;
    const modal = (
      <EditTop10Modal propertyId={propertyId} propertyName={name} />
    );
    ModalController.showModal(modal);
  };

  showMovieSpotlightModal = (item) => {
    const { id, name } = item;
    const modal = (
      <MovieSpotlightModal propertyId={id} propertyName={name} />
    );
    ModalController.showModal(modal);
  };

  showHomePageSpotlightModal = (item) => {
    const { id, name } = item;
    const modal = (
      <HomePageSpotlightModal propertyId={id} propertyName={name} />
    );
    ModalController.showModal(modal);
  }

  showPlaylistPlayerModal = () => {
    const modal = (
      <VideoModal playlist={true} />
    );
    ModalController.showModal(modal);
  };

  showPlayerConfigModal = () => {
    const modal = (
      <ConfigModal />
    );
    ModalController.showModal(modal);
  };

  showPlayerModal = () => {
    const modal = (
      <VideoModal playlist={false} />
    );
    ModalController.showModal(modal);
  };

  showSceneSpotlightModal = (item) => {
    const { id, name } = item;
    const modal = (
      <SceneSpotlightModal propertyId={id} propertyName={name} />
    );
    ModalController.showModal(modal);
  };

  showEditSpecialCategoriesModal = (item) => {
    const { id, name } = item;
    const modal = (
      <EditSpecialCategoriesModal propertyId={id} propertyName={name} />
    )
    ModalController.showModal(modal);
  }

  showSetNATSCodeModal = (property) => {
    const { id, name } = property;
    const modal = <EditNATSCodeModal propertyId={id} propertyName={name} />;
    ModalController.showModal(modal);
  }

  showEditBanedWordsModal = (property) => {
    const { id, name } = property;
    const modal = <EditBannedWordsModal propertyId={id} propertyName={name} />;
    ModalController.showModal(modal);
  };

  showEditHTMLContent = (property) => {
    const { id, name } = property;
    const modal = <EditHTMLContentModal propertyId={id} propertyName={name} />;
    ModalController.showModal(modal);
  };

  showEditSearchOptionsModal = (property) => {
    const { id, name } = property;
    const modal = <EditSearchOptionsModal propertyId={id} propertyName={name} />;
    ModalController.showModal(modal);
  };

  renderDisplayFilterItem = item => {
    const { t } = this.props;
    return (
      <Select.Option key={item} value={item}>
        <div>{t(`PropertiesBody.${item}`)}</div>
      </Select.Option>
    );
  };

  onFilterChange = () => {
    const { display } = this.state.filters;
    let visible = -1;
    if (display === 'Hidden') {
      visible = 0;
    } else if (display === 'Visible') {
      visible = 1;
    }
    this.props.onChange(visible);
  };

  onDisplayChange = status => {
    this.setState(prevState => {
      return {
        filters: {
          ...prevState.filters,
          display: status
        }
      };
    }, this.onFilterChange);
  };

  renderHeader = () => {
    const { t, listInfo: { properties = [] } = {} } = this.props;
    return (
      <div className='Header'>
        <div className='InputWrapper' title={properties.length}>
          <div>{t('PropertiesMainView.display')}:</div>
          <Select
            showArrow
            onChange={this.onDisplayChange}
            value={this.state.filters.display}>
            {this.state.displayOptions.map(this.renderDisplayFilterItem)}
          </Select>
        </div>
      </div>
    );
  };

  render() {
    const { t, listInfo } = this.props;
    const scroll = this.getScrollHeight();
    return (
      <LoadingWrapper className='PropertiesMainView'
        dataLoaded={listInfo.dataLoaded}
        isLoading={listInfo.loading}
        loadingMsg={t('PropertiesMainView.loadingPleaseWait')}>
        {this.renderHeader()}
        <PropertiesBody properties={listInfo.properties}
          onChange={this.onFilterChange}
          scroll={scroll}
          showEditCatalogModal={this.showEditCatalogModal}
          showCopyCatalogModal={this.showCopyCatalogModal}
          showEditPropertyModal={this.showEditPropertyModal}
          showEditSpecialCategoriesModal={this.showEditSpecialCategoriesModal}
          showEditTop10Modal={this.showEditTop10Modal}
          showConfigureCategoriesModal={this.showConfigureCategoriesModal}
          showMovieSpotlightModal={this.showMovieSpotlightModal}
          showHomePageSpotlightModal={this.showHomePageSpotlightModal}
          showSceneSpotlightModal={this.showSceneSpotlightModal}
          showSetBrowseConfigurationModal={this.showSetBrowseConfigurationModal}
          showSetNATSCodeModal={this.showSetNATSCodeModal}
          showEditBanedWordsModal={this.showEditBanedWordsModal}
          showEditHTMLContent={this.showEditHTMLContent}
          showEditSearchOptionsModal={this.showEditSearchOptionsModal}
        />
        {this.renderFooter()}
      </LoadingWrapper>
    );
  }
}

PropertiesMainView.propTypes = {
  listInfo: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired
};

export default withTranslation()(PropertiesMainView);
