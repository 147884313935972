import React, { useEffect, useRef, useState } from 'react';
import { Button, Select } from 'antd';

import ModalDialog from '../../../../../../../../../../components/modal/ModalDialog/ModalDialog';
import InfoTooltip from '../../../../../../../../../../components/InfoTooltip/InfoTooltip';
import { HtmlEditor } from '../../../../../../../HtmlEditor/HtmlEditor';
import ConfirmationModal from '../../../../../../../../../../components/modal/ConfirmationModal/ConfirmationModal';

import {
  getWhitelabelConfig,
  editWhitelabelConfig,
  handleHtmlContent
} from '../../../../../../../../../../services/properties-service/properties.service';
import {
  displayErrorNotification,
  displaySuccessNotification
} from '../../../../../../../../../../services/notification-service/notification.service';
import { ModalController } from '../../../../../../../../../../controllers/modal-controller/modal.controller';

import './EditHTMLContentModal.scss';

const FE_PAGES = {
  privacy: { name: 'privacy', label: 'Privacy Policy', content: '' },
  help: { name: 'help', label: 'Faq & Support', content: '' },
  tos: { name: 'tos', label: 'Terms of Use', content: '' },
  compliance: { name: 'compliance', label: 'Compliance', content: '' }
};

const FE_SECTIONS = {
  pages: { name: 'pages', label: 'Pages' },
  support_service: { name: 'support_service', label: 'Support Service', content: '' },
  footer_text: { name: 'footer_text', label: 'Footer Text', content: '' },
  footer_links: { name: 'footer_links', label: 'Footer Links', content: '' }
};

const getAllSections = (withoutContent = false) => {
  const allSections = { ...FE_SECTIONS, ...FE_PAGES };

  if (withoutContent) {
    return allSections;
  }

  // get sections with content
  let obj = {};
  for (const key in allSections) {
    if (allSections[key].hasOwnProperty('content')) {
      Object.assign(obj, { [key]: allSections[key] });
    }
  }
  return obj;
};

const PREVIOUS_CONTENT = {};

const EditHTMLContentModal = (props) => {
  const { propertyName, propertyId } = props;
  const [state, setState] = useState({
    htmlContent: {
      ...getAllSections()
    },
    selectedSection: FE_PAGES.privacy.name,
    loading: true,
    error: false
  });

  const {
    htmlContent,
    selectedSection,
    loading,
    error
  } = state;

  const modalRef = useRef(null);
  const modalElem = () => modalRef.current;

  const renderNotification = (msg, duration = 3, isError) => {
    const type = isError ? displayErrorNotification : displaySuccessNotification;
    return (
      type({
        duration,
        message: msg
      })
    )
  };

  const postData = () => {
    modalElem().setLoading('Loading');

    let editFn = Promise.resolve();

    if ((selectedSection === FE_SECTIONS.footer_links.name) || (selectedSection === FE_SECTIONS.footer_text.name)) {
      editFn = editWhitelabelConfig(propertyId, { [selectedSection]: htmlContent[selectedSection] });
    } else {
      editFn = handleHtmlContent({
        method: 'patch',
        req: 'append',
        propertyId,
        data: {
          html_content: {
            [selectedSection]: htmlContent[selectedSection]
          }
        }
      });
    }

    editFn
      .then(() => renderNotification('Edit success!'))
      .catch(err => {
        renderNotification('Edit failed!', 6, 1);
        console.log(err);
      })
      .finally(() => {
        modalElem().clearLoading();
        modalElem().closeModal();
      })
  };

  const onSaveBtnClick = () => {
    const msg = `Are you sure you want to edit "${getAllSections(1)[selectedSection]?.label}" section?`;
    const modal = (
      <ConfirmationModal
        title={`Edit HTML Content - ${propertyName}`}
        message={msg}
        yesBtnText='Confirm'
        noBtnText='Cancel'
        confirm={postData} />
    );
    ModalController.showModal(modal);
  };

  const onEditHtmlContent = (data) => {
    setState(prev => ({
      ...prev,
      htmlContent: {
        ...prev.htmlContent,
        [selectedSection]: {
          ...htmlContent[selectedSection],
          content: data
        }
      }
    }));
  };

  const onSelectChange = (val) => {
    let sSection = val;

    // on section change
    if (sSection === 'pages') {
      sSection = FE_PAGES.privacy.name;
    }
    setState(prev => ({ ...prev, selectedSection: sSection }));
  };

  const getModalActions = () => {
    return (
      <>
        <Button onClick={() => modalElem().closeModal()}>Cancel</Button>
        <Button
          onClick={() => renderDownloadBtn()}
          type='danger'
          disabled={getCurrentContent().length === 0}
        >
          Backup file
        </Button>
        <Button disabled={error ? true : false} onClick={() => onSaveBtnClick()}>Save changes</Button>
      </>
    )
  };

  const renderSelect = (options = {}, label) => {
    const defaultValue = Object.keys(options)[0];

    return (
      <>
        {label && <span className='Label'>{label}</span>}
        <Select
          defaultValue={defaultValue}
          style={{ width: 150, margin: '0 1.5em 0 .5em' }}
          onChange={onSelectChange}
        >
          {Object.entries(options)?.map(item => {
            const val = item?.[0];
            const text = item?.[1].label;
            return <Select.Option value={val} key={val}>{text}</Select.Option>
          })}
        </Select>
      </>
    )
  };

  // backup previous version to your PC
  const renderDownloadBtn = () => {
    const section = PREVIOUS_CONTENT[selectedSection];
    const today = new Date().toLocaleDateString();
    const fileName = `${getAllSections(1)[selectedSection]?.label}_${today}.html`;
    const blob = new Blob([section?.content], { type: 'text/html' });
    const a = document.createElement('a');
    a.download = fileName;
    a.href = URL.createObjectURL(blob);
    a.dataset.downloadurl = ['text/html', a.download, a.href].join(':');
    a.style.display = 'none';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    setTimeout(() => { URL.revokeObjectURL(a.href) }, 1500);
  };

  const getCurrentContent = () => htmlContent[selectedSection]?.content || '';

  const renderSize = () => {
    const kb = (getCurrentContent().length / 1024).toFixed(1);

    return (
      <span style={{ minWidth: '55px', display: 'inline-block', textAlign: 'center', margin: '0 1.5em 0 0' }}>{kb} KB</span>
    )
  };

  const renderView = () => {
    if (loading) return null;
    const showPagesSelect = selectedSection === 'pages' || Object.keys(FE_PAGES).includes(selectedSection);

    return (
      <>
        <div className='Section'>
          {renderSelect(FE_SECTIONS, 'Section')}
          {showPagesSelect && renderSelect(FE_PAGES, 'Page')}
          {renderSize()}
        </div>
        {!error && <HtmlEditor htmlContent={getCurrentContent()} onEditHtmlContent={onEditHtmlContent} />}
      </>
    )
  };

  useEffect(() => {
    const deepMerge = (target, source) => {
      const result = { ...target, ...source };
      for (const key of Object.keys(result)) {
        result[key] =
          typeof target[key] == 'object' && typeof source[key] == 'object'
            ? deepMerge(target[key], source[key])
            : window.structuredClone(result[key]);
      }
      return result;
    };

    modalElem().setLoading('Loading');

    const promises = [handleHtmlContent({ propertyId }), getWhitelabelConfig(propertyId)];
    Promise.all(promises)
      .then(resp => {
        const htmlContent = resp[0]?.data?.data || {};
        const {
          footer_links = FE_SECTIONS.footer_links,
          footer_text = FE_SECTIONS.footer_text
        } = resp[1]?.data?.data || {};

        const data = { ...htmlContent, footer_links, footer_text };

        if (data && Object.keys(data).length !== 0) {
          // response data replaces state data 
          const combinedData = deepMerge(getAllSections(), data);
          setState(prev => ({ ...prev, htmlContent: combinedData }));
          Object.assign(PREVIOUS_CONTENT, data);
        }
      })
      .catch(err => {
        renderNotification('Loading failed!', 6, 1);
        setState(prev => ({ ...prev, error: true }));
        console.log(err);
      })
      .finally(() => {
        setState(prev => ({ ...prev, loading: false }));
        modalElem().clearLoading();
      })
    // eslint-disable-next-line
  }, [propertyId]);

  const renderModalTitle = () => {
    return (
      <div>
        <InfoTooltip text={`
          --- FORMATTING CONTENT EXAMPLE ---
          Can have one <style> tag!!!
          
          <style>
              .ExampleClass {
                  background: #ddd;
                  padding: 5px;
              }  
              p {
                  color: var(--primary); 
                  border: 2px solid var(--secondary);
              }
          </style> 

          <div class='ExampleClass'>
              Lorem ipsum dolor sit amet consectetur adipisicing elit.
          </div> 

          <p style='padding: 2px'>This is a paragraph.</p>

          --- FORMATTING VARIABLES EXAMPLE ---

         \${REACT_APP_SITE_NAME} Terms and Conditions of Use and Disclosure -> NakedSword.com Terms and Conditions of Use and Disclosure
        `}
          overlayStyle={{ whiteSpace: 'break-spaces', maxWidth: 440, maxHeight: 380, overflow: 'auto' }} />
        Edit HTML content - {propertyName}
      </div>
    )
  };

  return (
    <ModalDialog className='EditHTMLContentModal'
      forwardedRef={modalRef}
      title={renderModalTitle()}
      actions={getModalActions()}>
      <div className='EditHTMLContentModal-inner'>
        {renderView()}
      </div>
    </ModalDialog>
  )
};

export default EditHTMLContentModal;