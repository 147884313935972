import React from 'react';
import { Icon, Tooltip } from 'antd';

import './InfoTooltip.scss';

const InfoTooltip = (props) => {
  const { text = '', overlayStyle } = props;

  if (!text) return null;

  return (
    <Tooltip title={text} className='InfoTooltip' overlayStyle={overlayStyle}>
      <Icon type='info-circle' />
    </Tooltip>
  )
};

InfoTooltip.displayName = 'InfoTooltip';

export default InfoTooltip;